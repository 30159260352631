import { TDS, Text } from '@/components/ui';
import { BUSINESS_CONTACT_URL } from '@/constants/external-link';
import {
  useConsoleSettingAction,
  useConsoleSettingValue,
} from '@/contexts/providers/console-setting-provider';
import { Actor } from '@/utils/actor-id';
import { gtag } from '@/utils/gtag';

export function SelectCharacter() {
  const { setSelectedCharacter } = useConsoleSettingAction();
  const { selectedCharacter } = useConsoleSettingValue();
  return (
    <>
      <Text className="font-medium">Character</Text>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 mt-2 md:mt-3">
        <TDS.Button
          className="flex-1"
          variant={selectedCharacter === 'Camila' ? 'outline' : 'solid'}
          colorScheme={selectedCharacter === 'Camila' ? 'primary' : 'grey'}
          fontSize={14}
          fontWeight={700}
          height={10}
          onClick={() => {
            gtag('select_camila_character_button_click');
            setSelectedCharacter(Actor.Camila);
          }}
        >
          Camila
        </TDS.Button>
        <TDS.Button
          className="flex-1"
          variant={selectedCharacter === 'ChanGu' ? 'outline' : 'solid'}
          colorScheme={selectedCharacter === 'ChanGu' ? 'primary' : 'grey'}
          fontSize={14}
          fontWeight={700}
          height={10}
          onClick={() => {
            gtag('select_changu_character_button_click');
            setSelectedCharacter(Actor.ChanGu);
          }}
        >
          Chan-Gu
        </TDS.Button>
        <TDS.Button
          className="flex-1"
          variant={selectedCharacter === 'Leo' ? 'outline' : 'solid'}
          colorScheme={selectedCharacter === 'Leo' ? 'primary' : 'grey'}
          fontSize={14}
          fontWeight={700}
          height={10}
          onClick={() => {
            gtag('select_hajun_character_button_click');
            setSelectedCharacter(Actor.HaJun);
          }}
        >
          Hajun
        </TDS.Button>
        <TDS.Button
          className="flex-1"
          colorScheme="grey"
          fontSize={14}
          fontWeight={700}
          height={10}
          onClick={() => {
            gtag('personalize_button_click');
            window.open(BUSINESS_CONTACT_URL);
          }}
        >
          Personalize
        </TDS.Button>
      </div>
    </>
  );
}
