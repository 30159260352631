import { HttpClient } from '@/libs/http-client';
import type { Endpoint } from '@/libs/http-client/types';

import type {
  GetCustomTokenRequestDTO,
  GetCustomTokenResponseDTO,
  GetIsExistUserRequestDTO,
  GetIsExistUserResponseDTO,
  GetProvidersResponseDTO,
  GetProvidersResquestDTO,
  ResetPasswordRequestDTO,
} from './types/typecast';

const baseURL = import.meta.env.VITE_TYPECAST_API_URL;
const httpClient = new HttpClient({ baseURL });

export async function requestGetIsExistUser(payload: GetIsExistUserRequestDTO) {
  try {
    const res = await httpClient.request<GetIsExistUserResponseDTO>(
      {
        url: `/api/auth-fb/v2/exists`,
        method: 'get',
        noAuth: true,
      },
      {
        provider: payload.provider,
        provider_uid: payload.providerUid,
        id_token: payload.idToken,
      },
    );

    return res.data.exists;
  } catch (err) {
    return false;
  }
}

export async function requestGetProviders(payload: GetProvidersResquestDTO) {
  try {
    const res = await httpClient.request<GetProvidersResponseDTO>(
      {
        url: '/api/auth-fb/v2/provider',
        method: 'get',
        noAuth: true,
      },
      {
        email: encodeURIComponent(payload.email),
      },
    );

    return res.data.providers;
  } catch (err) {
    return [];
  }
}

export async function requestGetCustomToken(payload: GetCustomTokenRequestDTO) {
  try {
    const res = await httpClient.request<GetCustomTokenResponseDTO>(
      {
        url: '/api/auth-fb/custom-token',
        method: 'post',
        noAuth: true,
      },
      {
        token: payload.token,
      },
    );

    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function requestGetActor() {
  const endPoint: Endpoint = {
    url: '/api/actor',
    method: 'get',
  };
  return await httpClient.request(endPoint, { role: 'prosody' });
}

export async function requestResetPassword(payload: ResetPasswordRequestDTO) {
  const endPoint: Endpoint = {
    url: `/api/auth-fb/email/reset-password`,
    method: 'post',
    noAuth: true,
  };

  return await httpClient.request(endPoint, { email: payload.email });
}
