import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '@unocss/reset/tailwind.css';
import { OverlayProvider } from 'overlay-kit';
// eslint-disable-next-line import/no-unresolved
import 'unfonts.css';
// eslint-disable-next-line import/no-unresolved
import 'virtual:uno.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { AuthenticationProvider } from './contexts/providers/authentication-provider.tsx';
import { HotjarProvider } from './contexts/providers/hotjar.tsx';
import { ScreenProvider } from './contexts/providers/screen-provider.tsx';
import './i18n';
import { ThemeProvider, theme } from './libs/chakra';
import './main.css';
import { routes } from './router.tsx';
import { isProdMode } from './utils/env-info.ts';

Sentry.init({
  dsn: 'https://7810df5fa37fe04326bad9be452413cf@o354244.ingest.us.sentry.io/4507540984758272',
  environment: import.meta.env.MODE,
  release: import.meta.env.VITE_CIRCLE_TAG,
  enableTracing: false,
  tracesSampleRate: 0.05,
});

const router = createBrowserRouter(routes);

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <ScreenProvider>
          <AuthenticationProvider>
            <OverlayProvider>
              <RouterProvider router={router} />
              {isProdMode && <HotjarProvider />}
            </OverlayProvider>
          </AuthenticationProvider>
        </ScreenProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
