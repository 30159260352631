import { assertUnreachable } from '@/utils/env-info';

export enum Actor {
  Camila = 'Camila',
  ChanGu = 'ChanGu',
  HaJun = 'Leo', //실제 이름을 레오로 바꾼 이유는 목소리 이슈떄문에 관련 슬랙: https://neosapience.slack.com/archives/C05L4AEKTGW/p1721019434149169?thread_ts=1721010852.263509&cid=C05L4AEKTGW
}
/**
 * @description Production/DevelopmentMode Actor Id
 */
export const getActorId = (actor: Actor): string => {
  switch (actor) {
    case Actor.Camila:
      return '5f8d7b0de146f10007b8042f';
    case Actor.ChanGu:
      return '5c547544fcfee90007fed455';
    case Actor.HaJun:
      return '62fb678f9b93d9207fa8c032';
    default:
      assertUnreachable(actor);
  }
};
