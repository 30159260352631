import { isAxiosError } from 'axios';
import { FirebaseError } from 'firebase/app';

import { TypecastAuthError } from '../errors/TypecastAuthError';

export class ErrorManager {
  static logError(error: unknown) {
    // FIXME: handle error for log
    console.error(error);
  }

  static isTypecastNetworkError(err: unknown): err is Error {
    if (!(err instanceof Error)) {
      return false;
    }

    if (isAxiosError(err)) {
      return !err.response;
    }

    return false;
  }
  static isFirebaseError(err: unknown): err is FirebaseError {
    return err instanceof FirebaseError;
  }
  static isTypecastAuthError(err: unknown): err is TypecastAuthError {
    return err instanceof TypecastAuthError;
  }

  static convertToTypecastAuthError(err: FirebaseError) {
    if (err.code === 'auth/invalid-email') {
      // case: 이메일 양식 오류
      return new TypecastAuthError('auth/invalid-email');
    }
    if (err.code === 'auth/user-not-found') {
      // case: 가입되어 있지 않은 유저
      return new TypecastAuthError('auth/user-not-found');
    }
    if (err.code === 'auth/wrong-password') {
      // case: 이메일 또는 비밀번호가 잘못된 경우
      return new TypecastAuthError('auth/wrong-password');
    }
    if (err.code === 'auth/too-many-requests') {
      // case: 짧은 시간내에 많은 요청을 보낸 경우
      return new TypecastAuthError('auth/too-many-requests');
    }
    if (err.code === 'auth/empty-email-in-account') {
      // case: 계정에 이메일이 없어서 로그인이 안되는 경우
      return new TypecastAuthError('auth/empty-email-in-account');
    }
    if (err.code === 'auth/cancelled-popup-request') {
      // case: 팝업이 이미 열려있는데 다른 팝업이 또 열려서 해당 팝업의 동작이 멈춘 경우
      return new TypecastAuthError('auth/cancelled-popup-request');
    }
    if (err.code === 'auth/account-exists-with-different-credential') {
      // case: 이메일은 같고, 다른 provider를 사용하는 경우
      return new TypecastAuthError(
        'auth/account-exists-with-different-credential',
      );
    }
    if (err.code === 'auth/popup-blocked') {
      // case: 브라우저 설정 상 팝업이 막혀있음
      return new TypecastAuthError('auth/popup-blocked');
    }

    return new TypecastAuthError('auth/common');
  }
}
