import chatBubble from '@/assets/images/chatbubble.png';
import previewSampleCamila from '@/assets/images/preview-sample-camila.png';
import previewSampleChangu from '@/assets/images/preview-sample-changu.png';
import previewSampleHajun from '@/assets/images/preview-sample-hajun.png';
import { BACKGROUND_COLOR } from '@/components/console-setting/constants/background-color';
import { useConsoleSettingValue } from '@/contexts/providers/console-setting-provider';
import { useScreenStore } from '@/stores/screen';
import { cn, getVideoFileType, isVideoFileExtension } from '@/utils';
import { Actor } from '@/utils/actor-id';
import { assertUnreachable } from '@/utils/env-info';

export function CharacterPreview() {
  const { isMobile } = useScreenStore();
  const { selectedCharacter, bubbleFlag, backgroundLocalPath, backgroundName } =
    useConsoleSettingValue();
  const characterImage = useMemo(() => {
    switch (selectedCharacter) {
      case Actor.Camila:
        return previewSampleCamila;
      case Actor.ChanGu:
        return previewSampleChangu;
      case Actor.HaJun:
        return previewSampleHajun;
      default:
        assertUnreachable(selectedCharacter);
    }
  }, [selectedCharacter]);

  const isVideoFile = isVideoFileExtension(backgroundName);
  const fileType = getVideoFileType(backgroundName);
  const _fileType = fileType === 'mov' ? 'mp4' : fileType;

  return (
    <div
      className="w-full h-157 rounded-xl relative"
      style={{
        ...BACKGROUND_COLOR,
        backgroundImage: `url(${isVideoFile ? '' : backgroundLocalPath})`,
        backgroundSize: 'cover',
      }}
    >
      {isVideoFile && (
        <video
          key={backgroundLocalPath as string}
          id="myVideo"
          className="w-full h-full rounded-xl"
          autoPlay
          muted
          loop
        >
          <source
            src={backgroundLocalPath as string}
            type={`video/${_fileType}`}
          />
        </video>
      )}
      {bubbleFlag && (
        <img
          className={cn('absolute md:right-6 top-20 right-2 z-2', {
            'w-52': isMobile,
          })}
          src={chatBubble}
          alt="streaming-sample-message"
        />
      )}

      <img
        className="mx-auto absolute bottom-0 left-1/2 -translate-x-1/2 z-2"
        src={characterImage}
        alt="streaming-sample-character"
      />
    </div>
  );
}
