import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import type { User } from '@/types/user';

export type UserSlice = {
  isAuthorized: boolean;
  user?: Partial<User>;
  setUser: (user: Partial<User>) => void;
  clearUser: () => void;
};

export const useUserStore = create<UserSlice>()(
  devtools(
    set => ({
      user: undefined,
      isAuthorized: false,
      setUser: (user: Partial<User>) => set({ user, isAuthorized: true }),
      clearUser: () => set({ user: undefined, isAuthorized: false }),
    }),
    { name: 'UserStore' },
  ),
);
