import { isNull } from 'es-toolkit';
import { isEqual } from 'lodash-es';

import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  type ReactElement,
} from 'react';

import type { Vtube } from '@/api/types/console-setting';
import type { Actor } from '@/utils/actor-id';

const ConsoleSettingValueContext = createContext<{
  bubbleFlag: boolean;
  backgroundName: string;
  backgroundPath: string;
  backgroundImageFile: File | null;
  backgroundLocalPath: string | ArrayBuffer | null;
  identity: string;
  examples: Example[];
  selectedCharacter: Actor;
  isDirty: boolean;
} | null>(null);

const ConsoleSettingActionContext = createContext<{
  setBubbleFlag: Dispatch<SetStateAction<boolean>>;
  setIdentity: Dispatch<SetStateAction<string>>;
  setBackgroundPath: Dispatch<SetStateAction<string>>;
  setBackgroundFileAndFileName: (file: File) => void;
  setBackgroundImageFile: Dispatch<SetStateAction<File | null>>;
  setBackgroundLocalPath: Dispatch<SetStateAction<string | ArrayBuffer | null>>;
  updateExample: (index: number, message: string) => void;
  deleteExample: (index: number) => void;
  deleteAllExamples: () => void;
  addExample: () => void;
  resetToSnapShot: () => void;
  setSelectedCharacter: Dispatch<SetStateAction<Actor>>;
  updateSnapShotByCurrentSetting: () => void;
} | null>(null);

interface ConsoleSettingProviderProps {
  children: ReactElement;
  initialValues: {
    selectedCharacter: Actor;
    bubbleFlag: Vtube['bubble_flag'];
    backgroundName: Vtube['background_name'];
    backgroundPath: Vtube['background_path'];
    backgroundUrl: Vtube['background_url'];
    identity: string;
    examples: Example[];
  };
}

export function ConsoleSettingProvider({
  initialValues,
  children,
}: ConsoleSettingProviderProps) {
  const [selectedCharacter, setSelectedCharacter] = useState<Actor>(
    initialValues.selectedCharacter,
  );
  const [bubbleFlag, setBubbleFlag] = useState(initialValues.bubbleFlag);
  const [backgroundName, setBackgroundName] = useState(
    initialValues.backgroundName,
  );
  const [backgroundPath, setBackgroundPath] = useState(
    initialValues.backgroundPath,
  );
  const [backgroundImageFile, setBackgroundImageFile] = useState<File | null>(
    null,
  );
  const [backgroundLocalPath, setBackgroundLocalPath] = useState<
    string | ArrayBuffer | null
  >(initialValues.backgroundUrl);
  const {
    examples,
    updateExample,
    updateExampleList,
    deleteExample,
    deleteAllExamples,
    addExample,
  } = useExamples(
    initialValues.examples.length === 0
      ? DEFAULT_EXAMPLES
      : initialValues.examples,
  );
  //identity - system,
  //question - user,
  //answer - assistant
  const [identity, setIdentity] = useState(initialValues.identity);

  const [selectedCharacterSnapShot, setSelectedCharacterSnapShot] =
    useState<Actor>(initialValues.selectedCharacter);
  const [bubblgFlagSnapShot, setBubbleFlagSnapShot] = useState(
    initialValues.bubbleFlag,
  );
  const [exampleSnapShot, setExampleSnapShot] = useState<Example[]>(
    initialValues.examples.length === 0
      ? DEFAULT_EXAMPLES
      : initialValues.examples,
  );
  const [identitySnapShot, setIdentitySnapShot] = useState(
    initialValues.identity,
  );
  const [backgroundLocalPathSnapShot, setBackgroundLocalPathSnapShot] =
    useState<string | ArrayBuffer | null>(initialValues.backgroundUrl);

  const [backgroundImageFileSnapShot, setBackgroundImageFileSnapShot] =
    useState<File | null>(null);
  const [backgroundNameSnapShot, setBackgroundNameSnapShot] = useState(
    initialValues.backgroundName,
  );
  const [backgroundPathSnapShot, setBackgroundPathSnapShot] = useState(
    initialValues.backgroundPath,
  );

  const isDirty = useMemo(() => {
    return (
      selectedCharacter !== selectedCharacterSnapShot ||
      bubbleFlag !== bubblgFlagSnapShot ||
      identity !== identitySnapShot ||
      backgroundName !== backgroundNameSnapShot ||
      backgroundPath !== backgroundPathSnapShot ||
      backgroundImageFile !== backgroundImageFileSnapShot ||
      !isEqual(examples, exampleSnapShot)
    );
  }, [
    selectedCharacter,
    bubbleFlag,
    identity,
    examples,
    selectedCharacterSnapShot,
    bubblgFlagSnapShot,
    identitySnapShot,
    exampleSnapShot,
    backgroundName,
    backgroundPath,
    backgroundImageFile,
  ]);

  const setBackgroundFileAndFileName = useCallback(
    (file: File) => {
      setBackgroundName(file.name);
      setBackgroundImageFile(file);
    },
    [setBackgroundName, setBackgroundImageFile],
  );

  const resetToSnapShot = useCallback(() => {
    setSelectedCharacter(selectedCharacterSnapShot);
    setBubbleFlag(bubblgFlagSnapShot);
    setIdentity(identitySnapShot);
    updateExampleList(exampleSnapShot);
    setBackgroundLocalPath(backgroundLocalPathSnapShot);
    setBackgroundPath(backgroundPathSnapShot);
    setBackgroundName(backgroundNameSnapShot);
    setBackgroundImageFile(backgroundImageFileSnapShot);
  }, [
    selectedCharacterSnapShot,
    bubblgFlagSnapShot,
    identitySnapShot,
    exampleSnapShot,
  ]);

  const updateSnapShotByCurrentSetting = useCallback(() => {
    setSelectedCharacterSnapShot(selectedCharacter);
    setBubbleFlagSnapShot(bubbleFlag);
    setIdentitySnapShot(identity);
    setExampleSnapShot(examples);
    setBackgroundLocalPathSnapShot(backgroundLocalPath);
    setBackgroundNameSnapShot(backgroundName);
    setBackgroundPathSnapShot(backgroundPath);
    setBackgroundImageFileSnapShot(backgroundImageFile);
  }, [
    selectedCharacter,
    bubbleFlag,
    identity,
    examples,
    backgroundLocalPath,
    backgroundName,
    backgroundPath,
    backgroundImageFile,
  ]);

  const actions = useMemo(() => {
    return {
      setSelectedCharacter,
      setBubbleFlag,
      setIdentity,
      setBackgroundPath,
      setBackgroundImageFile,
      setBackgroundFileAndFileName,
      setBackgroundLocalPath,
      updateExample,
      deleteExample,
      deleteAllExamples,
      addExample,
      resetToSnapShot,
      updateSnapShotByCurrentSetting,
    };
  }, [
    setSelectedCharacter,
    setBubbleFlag,
    setIdentity,
    setBackgroundImageFile,
    setBackgroundPath,
    setBackgroundFileAndFileName,
    setBackgroundLocalPath,
    updateExample,
    deleteExample,
    deleteAllExamples,
    addExample,
    resetToSnapShot,
    updateSnapShotByCurrentSetting,
    setBackgroundImageFile,
  ]);

  const values = useMemo(() => {
    return {
      selectedCharacter,
      bubbleFlag,
      backgroundName,
      backgroundPath,
      backgroundImageFile,
      backgroundLocalPath,
      identity,
      examples,
      isDirty,
    };
  }, [
    selectedCharacter,
    bubbleFlag,
    backgroundPath,
    backgroundImageFile,
    backgroundLocalPath,
    identity,
    examples,
    isDirty,
  ]);

  return (
    <ConsoleSettingActionContext.Provider value={actions}>
      <ConsoleSettingValueContext.Provider value={values}>
        {children}
      </ConsoleSettingValueContext.Provider>
    </ConsoleSettingActionContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useConsoleSettingValue() {
  const value = useContext(ConsoleSettingValueContext);
  if (isNull(value)) {
    throw new Error(
      'useConsoleSettingValue ConsoleSettingValueContext 필요로 합니다',
    );
  }
  return value;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useConsoleSettingAction() {
  const value = useContext(ConsoleSettingActionContext);
  if (isNull(value)) {
    throw new Error(
      'useConsoleSettingAction은 ConsoleSettingActionContext 필요로 합니다',
    );
  }
  return value;
}

export type Example = {
  type: 'question' | 'answer';
  message: string;
};

export const DEFAULT_EXAMPLES = [
  {
    type: 'question',
    message: '',
  },
  {
    type: 'answer',
    message: '',
  },
] as Example[];

const useExamples = (initialExamples: Example[]) => {
  const [examples, setExamples] = useState<Example[]>(initialExamples);

  const updateExample = useCallback((index: number, message: string) => {
    setExamples(prevExamples => {
      const newExamples = [...prevExamples].map((prevExample, prevIndex) => {
        if (prevIndex === index) {
          return { type: prevExample.type, message: message };
        }
        return prevExample;
      });
      return newExamples;
    });
  }, []);

  const updateExampleList = useCallback((nextExampleList: Example[]) => {
    setExamples(nextExampleList);
  }, []);

  const deleteExample = useCallback((index: number) => {
    setExamples(prevExamples => {
      const newExamples = [...prevExamples].filter(
        (_, prevIndex) => prevIndex !== index,
      );
      return newExamples;
    });
  }, []);
  const deleteAllExamples = useCallback(() => {
    setExamples(DEFAULT_EXAMPLES);
  }, []);

  const addExample = useCallback(() => {
    setExamples(prevExamples => {
      if (prevExamples.length === 0) {
        return [{ type: 'question', message: '' }];
      }

      const prevLastExample = prevExamples[prevExamples.length - 1];
      if (prevLastExample.type === 'question') {
        return [...prevExamples, { type: 'answer', message: '' }];
      }
      return [...prevExamples, { type: 'question', message: '' }];
    });
  }, []);

  return {
    examples,
    updateExample,
    updateExampleList,
    deleteExample,
    deleteAllExamples,
    addExample,
  };
};
