import { BACKGROUND_COLOR } from '@/components/console-setting/constants/background-color';
import { Switch, TDS, Tooltip } from '@/components/ui';
import {
  useConsoleSettingAction,
  useConsoleSettingValue,
} from '@/contexts/providers/console-setting-provider';
import { gtag } from '@/utils/gtag';

export function ChatSetting() {
  const { bubbleFlag } = useConsoleSettingValue();
  const { setBubbleFlag } = useConsoleSettingAction();
  return (
    <div className="flex flex-col md:h-60">
      <div
        className="text-white font-black rounded-t-xl py-4 px-6 text-white flex items-center gap-0.5"
        style={BACKGROUND_COLOR}
      >
        <TDS.Typo weight="extrabold">Chat</TDS.Typo>
        <Tooltip
          hasArrow
          bg="gray.900"
          label="Each chat bubble displays a randomly chosen message from the chat."
          placement="right"
          onOpen={() => gtag('open_chat_tooltip_hover')}
        >
          <i className="i-t-info-small cursor-pointer" />
        </Tooltip>
      </div>
      <div className="flex-1 bg-white rounded-b-xl p-6 flex justify-between">
        <div>Chat Bubble</div>
        <Switch
          colorScheme="primary"
          isChecked={bubbleFlag}
          onChange={e => {
            gtag('toggle_chat_bubble_click');
            setBubbleFlag(e.target.checked);
          }}
        />
      </div>
    </div>
  );
}
