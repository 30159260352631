import type { ComponentPropsWithoutRef } from 'react';

import { useMount } from '@/hooks';
import { AccountManager, GoogleSignIn, type Token } from '@/libs/auth';

type GoogleSignInButtonProps = ComponentPropsWithoutRef<'div'> & {
  onBeforeSignIn?: () => void;
  onSignInError?: (err: Error) => void;
  onAfterSignIn?: (isSuccess: boolean) => void;
};

export function GoogleSignInButton({
  onBeforeSignIn,
  onSignInError,
  onAfterSignIn,
  ...props
}: GoogleSignInButtonProps) {
  const googleLoginRef = useRef<HTMLDivElement>(null);

  const handleSignInGoogle = async (token: Token) => {
    let isSuccess = true;
    try {
      onBeforeSignIn && onBeforeSignIn();
      await AccountManager.signInGoogle({ token });
    } catch (err) {
      isSuccess = false;
      onSignInError && onSignInError(err as Error);
    } finally {
      onAfterSignIn && onAfterSignIn(isSuccess);
    }
  };

  useMount(() => {
    setTimeout(() => {
      if (!googleLoginRef.current) {
        return;
      }

      GoogleSignIn.renderButton(googleLoginRef.current, token => {
        handleSignInGoogle(token);
      });
    }, 200); //FIXME: 이 부분이 모달에서 떠서 제대로 사이즈를 못가져 오는 문제가 있음
  });

  return (
    <div
      className="h-10 overflow-hidden w-full"
      ref={googleLoginRef}
      {...props}
    />
  );
}
