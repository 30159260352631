import { createContext } from 'react';
import { hotjar } from 'react-hotjar';

import { useUserStore } from '@/stores/user';

export const HotjarContext = createContext(hotjar);

export const HotjarProvider = () => {
  const user = useUserStore(state => state.user);

  hotjar.initialize({
    id: 5047189,
    sv: 6,
  });

  useEffect(() => {
    if (user?.uid) {
      hotjar.identify(user.uid, {
        name: user.name,
        email: user.email,
        profile: user.profile,
      });
    }
  }, [user]);

  return <HotjarContext.Provider value={hotjar} />;
};
