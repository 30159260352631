import { useShallow } from 'zustand/react/shallow';

import { ReactNode } from 'react';

import { AccountManager } from '@/libs/auth/managers/account';
import { TokenManager } from '@/libs/http-client/managers/token';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';
import { deleteCookie, getCookie } from '@/utils/cookie';

const ACCESS_TOKEN_KEY = 'tc_access_token';
const SIGNIN_TYPE = {
  ALREADY_SIGNUP: 'already-signup',
  COMPLETE_SIGNUP: 'complete-signup',
  SOCIAL_TYPE_SIGNIN: 'social-type-signin',
};

export const AuthenticationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const userStore = useUserStore(state => ({
    setUser: state.setUser,
    clearUser: state.clearUser,
  }));

  const loadingStore = useLoadingStore(useShallow(state => state));

  const params = new URLSearchParams(window.location.search);
  const signinType = params.get('signin-type');

  const autoSignin = async () => {
    try {
      loadingStore.showLoading();
      const accessTokenInCookie = getCookie(ACCESS_TOKEN_KEY) ?? '';
      deleteCookie(ACCESS_TOKEN_KEY);
      if (accessTokenInCookie) {
        await AccountManager.signInWithCustomToken(accessTokenInCookie);
      }
    } catch (error) {
      // TODO: Sentry integration for error logging
    } finally {
      loadingStore.hideLoading();
    }
  };

  useEffect(() => {
    AccountManager.init({
      onAccountUpdate: user => {
        if (!user) {
          userStore.clearUser();
        } else {
          userStore.setUser({
            profile: user.photoURL ?? undefined,
            email: user.email ?? undefined,
            uid: user.uid,
          });
        }
      },
    });
    TokenManager.init(AccountManager.getAccessToken);

    if (
      signinType === SIGNIN_TYPE.ALREADY_SIGNUP ||
      signinType === SIGNIN_TYPE.SOCIAL_TYPE_SIGNIN ||
      signinType === SIGNIN_TYPE.COMPLETE_SIGNUP
    ) {
      autoSignin();
    }
  }, []);

  return children;
};
