import type {
  DeleteIvideosResponseDTO,
  IvideoBroadcastsResponseDTO,
  PostIvideosResponseDTO,
  VtubeBackgroundUrlResponseDTO,
  VtubeSettingRequestDTO,
  VtubeSettingResponseDTO,
} from '@/api/types/console-setting';
import { type Endpoint, HttpClient } from '@/libs/http-client';

const baseURL = `${import.meta.env.VITE_LIVE_STAGE_URL}/api`;
const httpClient = new HttpClient({ baseURL });

export const consoleApi = {
  getVtubeSetting,
  updateVtubeSetting,
  postVtubeBackgroundUrl,
  getIvideoBroadcasts,
  postIvideo,
  deleteIvideo,
};

export async function getVtubeSetting() {
  const endPoint: Endpoint = {
    url: '/v1/vtube?bg=1',
    method: 'get',
  };
  const response = await httpClient.request<VtubeSettingResponseDTO>(endPoint);
  return response.data;
}

export async function updateVtubeSetting(data: VtubeSettingRequestDTO) {
  const endPoint: Endpoint = {
    url: '/v1/vtube',
    method: 'patch',
  };

  const response = await httpClient.request<VtubeSettingResponseDTO>(
    endPoint,
    data,
  );
  return response.data;
}

export async function postVtubeBackgroundUrl(backgroundName: string) {
  const endPoint: Endpoint = {
    url: '/v1/vtube/background_url',
    method: 'post',
  };

  const response = await httpClient.request<VtubeBackgroundUrlResponseDTO>(
    endPoint,
    { background_name: backgroundName },
  );
  return response.data;
}

export async function getIvideoBroadcasts(uid: string) {
  const endPoint: Endpoint = {
    url: `/v1/ivideo/broadcasts?uid=${uid}`,
    method: 'get',
  };
  const response =
    await httpClient.request<IvideoBroadcastsResponseDTO>(endPoint);
  return response.data;
}

export async function postIvideo(broker_pk: string) {
  const endPoint: Endpoint = {
    url: '/v1/ivideo',
    method: 'post',
  };

  const response = await httpClient.request<PostIvideosResponseDTO>(endPoint, {
    broker_pk,
  });
  return response.data;
}

export async function deleteIvideo(ivideo_pk: string, ivideo_sk: string) {
  const endPoint: Endpoint = {
    url: `/v1/ivideo/${ivideo_pk}/${ivideo_sk}`,
    method: 'delete',
  };

  const response = await httpClient.request<DeleteIvideosResponseDTO>(endPoint);
  return response.data;
}
