import { Input, Text, Tooltip } from '@chakra-ui/react';

import { ReactElement } from 'react';

import {
  useConsoleSettingAction,
  useConsoleSettingValue,
} from '@/contexts/providers/console-setting-provider';
import { TDS } from '@/libs/chakra';
import { gtag } from '@/utils/gtag';

export function ExampleSetting(): ReactElement {
  const { examples } = useConsoleSettingValue();
  const { addExample, deleteAllExamples } = useConsoleSettingAction();
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-0.5">
          <Text className="font-medium">Example</Text>
          <Tooltip
            hasArrow
            bg="gray.900"
            label="Please provide sample questions and answers to base your character's behavior on."
            placement="right"
            onOpen={() => gtag('open_example_tooltip_hover')}
          >
            <i className="i-t-info-small cursor-pointer" />
          </Tooltip>
        </div>
        <TDS.Button
          padding={0}
          variant="ghost"
          onClick={() => {
            gtag('delete_all_example_button_click');
            deleteAllExamples();
          }}
        >
          <i className="i-t-delete-char-large text-black" />
        </TDS.Button>
      </div>
      <div className="mt-2 md:mt-6 flex flex-col gap-4">
        {examples.map((example, index) => {
          return example.type === 'question' ? (
            <QuestionInput key={index} index={index} value={example.message} />
          ) : (
            <AnswerInput key={index} index={index} value={example.message} />
          );
        })}
      </div>
      <TDS.Button
        className="w-full mt-3"
        colorScheme="grey"
        leftIcon={<i className="i-t-plus" />}
        onClick={() => {
          gtag('add_example_button_click');
          addExample();
        }}
      >
        Add Message
      </TDS.Button>
    </>
  );
}

type InputProps = {
  index: number;
  value: string;
};

function QuestionInput({ index, value }: InputProps): ReactElement {
  const { updateExample, deleteExample } = useConsoleSettingAction();
  return (
    <div>
      <span className="border border-solid border-grey-100 bg-grey-50 rounded-2xl px-2 md:px-4 py-2 text-sm md:text-md">
        Question
      </span>
      <div className="flex items-center mt-2 gap-1">
        <Input
          borderRadius={12}
          placeholder="Viewers' Questions"
          value={value}
          onFocus={() => gtag('question_input_focus')}
          onChange={e => updateExample(index, e.target.value)}
        />
        <TDS.Button
          margin={0}
          padding={0}
          variant="ghost"
          onClick={() => {
            gtag('delete_question_button_click');
            deleteExample(index);
          }}
        >
          <i className="i-t-minus text-black text-2xl" />
        </TDS.Button>
      </div>
    </div>
  );
}

function AnswerInput({ index, value }: InputProps): ReactElement {
  const { updateExample, deleteExample } = useConsoleSettingAction();

  return (
    <div>
      <span className="border border-solid border-grey-100 bg-grey-50 rounded-2xl px-2 md:px-4 py-2 text-sm md:text-md">
        Answer
      </span>
      <div className="flex items-center mt-2 gap-1">
        <Input
          borderRadius={12}
          placeholder="Streamers's Answer"
          value={value}
          onFocus={() => gtag('answer_input_focus')}
          onChange={e => updateExample(index, e.target.value)}
        />
        <TDS.Button
          margin={0}
          padding={0}
          variant="ghost"
          onClick={() => {
            gtag('delete_answer_button_click');
            deleteExample(index);
          }}
        >
          <i className="i-t-minus text-black text-2xl" />
        </TDS.Button>
      </div>
    </div>
  );
}
