import 'dayjs/locale/en';
import 'dayjs/locale/ko';
import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';

import * as en from './languages/en-US';
import * as ko from './languages/ko-KR';

i18n.use(initReactI18next).init({
  debug: import.meta.env.DEV,
  fallbackLng: 'en',
  detection: { order: ['navigator', 'path'] },
  resources: {
    en,
    ko,
  },
});
