/**
 * @file useMediaQuery hook
 * chakra-ui's useMediaQuery 를 사용하였음 차후 chakra-ui를 사용한다면 해당 파일은 삭제 하고 chakra-ui의 useMediaQuery를 사용하도록 한다.
 * https://github.com/chakra-ui/chakra-ui/blob/311bd660a7e8bb91ef6c2e89939a6ceb2875ff4c/packages/hooks/src/use-media-query.ts#L24
 */
import { useCallbackRef } from '@/hooks/use-callback-ref';

type MediaQueryCallback = (event: MediaQueryListEvent) => void;

function listen(query: MediaQueryList, callback: MediaQueryCallback) {
  try {
    query.addEventListener('change', callback);
    return () => query.removeEventListener('change', callback);
  } catch (e) {
    query.addListener(callback);
    return () => query.removeListener(callback);
  }
}

export type UseMediaQueryOptions = {
  fallback?: boolean[];
  ssr?: boolean;
  getWindow?(): typeof window;
};

export function useMediaQuery(
  query: string[],
  options: UseMediaQueryOptions,
): boolean[] {
  const { fallback: _fallback, ssr = true, getWindow } = options;
  const getWin = useCallbackRef(getWindow);

  const queries = Array.isArray(query) ? query : [query];

  const fallback = _fallback?.filter(v => v != null) as boolean[];

  const [value, setValue] = useState(() => {
    return queries.map((query, index) => ({
      media: query,
      matches: !ssr
        ? (getWin() ?? window).matchMedia?.(query)?.matches
        : !!fallback[index],
    }));
  });

  useEffect(() => {
    const win = getWin() ?? window;
    setValue(prev => {
      const current = queries.map(query => ({
        media: query,
        matches: win.matchMedia(query).matches,
      }));

      return prev.every(
        (v, i) =>
          v.matches === current[i].matches && v.media === current[i].media,
      )
        ? prev
        : current;
    });

    const mql = queries.map(query => win.matchMedia(query));

    const handler = (evt: MediaQueryListEvent) => {
      setValue(prev => {
        return prev.slice().map(item => {
          if (item.media === evt.media)
            return { ...item, matches: evt.matches };
          return item;
        });
      });
    };

    const cleanups = mql.map(v => listen(v, handler));
    return () => cleanups.forEach(fn => fn());
  }, [getWin]);

  return value.map(item => item.matches);
}
