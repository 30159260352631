import { PropsWithChildren, ReactElement } from 'react';

import { SignInModal } from '@/components/auth/sign-in-modal';

export function AuthenticationGuardOverlay({
  children,
}: PropsWithChildren): ReactElement {
  return (
    <>
      {children}
      <SignInModal />
    </>
  );
}
