import { Tooltip, Textarea, Text } from '@chakra-ui/react';

import {
  useConsoleSettingValue,
  useConsoleSettingAction,
} from '@/contexts/providers/console-setting-provider';
import { TDS } from '@/libs/chakra';
import { useScreenStore } from '@/stores/screen';
import { gtag } from '@/utils/gtag';

export function IdentitySetting() {
  const { identity } = useConsoleSettingValue();
  const { setIdentity } = useConsoleSettingAction();
  const { isDesktop } = useScreenStore();
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-0.5">
          <Text className="font-medium">Identity</Text>
          <Tooltip
            hasArrow
            bg="gray.900"
            label="Please enter the details and background information of the character."
            placement="right"
            onOpen={() => gtag('open_identity_tooltip_hover')}
          >
            <i className="i-t-info-small cursor-pointer" />
          </Tooltip>
        </div>
        <TDS.Button
          padding={0}
          variant="ghost"
          onClick={() => {
            gtag('delete_identity_button_click');
            setIdentity('');
          }}
        >
          <i className="i-t-delete-char-large text-black" />
        </TDS.Button>
      </div>
      <Textarea
        className="resize-none md:mt-4"
        placeholder="Hello world"
        height={isDesktop ? 600 : 144}
        value={identity}
        onFocus={() => gtag('identity_textarea_focus')}
        onChange={e => setIdentity(e.target.value)}
      />
    </>
  );
}
