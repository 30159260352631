type OpenFileDialogOptions = Partial<{
  accepts: string[];
  multiple: boolean;
  capture: string;
}>;
/**
 * 파일 dialog를 열어 유저로부터 선택한 파일을 반환하는 유틸 함수
 *
 * @param options 파일 다이얼로그 옵션
 * @returns 선택한 파일, 선택을 취소하면 undefined를 반환
 */
export function openFileDialog({
  accepts = [],
  multiple = false,
  capture,
}: OpenFileDialogOptions): Promise<File[]> {
  return new Promise<File[]>(resolve => {
    const pickerEl = document.createElement('input');
    pickerEl.setAttribute('type', 'file');
    pickerEl.setAttribute('accept', accepts.join(','));

    if (multiple) {
      pickerEl.setAttribute('multiple', '');
    }

    if (capture) {
      pickerEl.setAttribute('capture', capture);
    }

    pickerEl.addEventListener('change', e => {
      const target = e.target as HTMLInputElement;
      if (!target || !target.files) return resolve([]);

      resolve([...target.files]);
    });
    pickerEl.addEventListener('cancel', () => {
      resolve([]);
    });

    pickerEl.click();
  });
}

/**
 * 주어진 파일이 특정 사이즈를 넘지 않는지 확인한다.
 *
 * @param limit 파일 한계 사이즈
 * @param file 파일
 * @returns 주어진 파일이 한계 사이즈를 넘지 않는지 여부
 */
export const isValidFileSize = (limit: number, file: File) => {
  return file.size <= limit;
};

/**
 * 주어진 파일이 특정 파일 타입에 포함되는지 확인한다.
 *
 * @param accepts 허용 파일 타입 배열
 * @param file 파일
 * @returns 주어진 파일이 허용된 파일 타입에 속하는지 여부
 */
export const isValidFileType = (accepts: string[], file: File) => {
  return accepts.includes(file.type);
};

/**
 * 주어진 값이 File 타입인지 확인한다.
 *
 * @param maybeFile 파일인지 확인이 필요한 값
 * @returns 파일인지 여부
 */
export const isFile = (maybeFile: unknown): maybeFile is File => {
  return maybeFile instanceof File;
};

/**
 * @description 주어진 파일명이 동영상 확장자인지 확인한다.
 */
export const isVideoFileExtension = (fileName: string) => {
  return /\.(mp4|mkv|mov)$/.test(fileName);
};

export const getVideoFileType = (filename: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const extension = filename.split('.')[1];
  return extension;
};
