import { Route, createRoutesFromElements } from 'react-router-dom';

import { AuthenticationGuardOverlay } from '@/components/AuthenticationGuardOverlay';
import ConsoleSettingPage from '@/pages/console-setting-page';
import NotFoundPage from '@/pages/not-found-page';

// import ConsoleSettingPage from '@/pages/console-setting-page';
// import SignInPage from '@/pages/sign-in-page';

export const routes = createRoutesFromElements(
  <Route>
    <Route
      path="/"
      element={
        <AuthenticationGuardOverlay>
          <ConsoleSettingPage />
        </AuthenticationGuardOverlay>
      }
    />
    <Route path="*" element={<NotFoundPage />} />
  </Route>,
);
