import { useQuery } from '@tanstack/react-query';

import { consoleApi } from '@/api/console-api';
import { QUERY_KEY } from '@/constants/query-key';
import { useUserStore } from '@/stores/user';

export const useIvideoBroadcastsQuery = () => {
  const { user } = useUserStore(state => ({
    user: state.user,
  }));

  const query = useQuery({
    queryKey: [QUERY_KEY.IVIDEO.BROADCASTS, user?.uid],
    queryFn: () => consoleApi.getIvideoBroadcasts(user?.uid ?? 'unknown'),
    enabled: Boolean(user?.uid),
  });
  const isBroadcasting = useMemo(() => {
    if (!query.data || query.data.broadcasts.length === 0) {
      return false;
    }
    if (query.data.broadcasts[0].ivideo?.ivideo_status === 'START') {
      return true;
    }
    return false;
  }, [query.data]);

  const ivideoPk = useMemo(() => {
    if (!query.data || query.data.broadcasts.length === 0) {
      return undefined;
    }
    return query.data.broadcasts[0].ivideo?.ivideo_pk;
  }, [query.data]);

  const ivideoSk = useMemo(() => {
    if (!query.data || query.data.broadcasts.length === 0) {
      return undefined;
    }
    return query.data.broadcasts[0].ivideo?.ivideo_sk;
  }, [query.data]);

  const ivideo = useMemo(() => {
    if (!query.data || query.data.broadcasts.length === 0) {
      return null;
    }
    return query.data.broadcasts[0].ivideo;
  }, [query.data]);

  return {
    ...query,
    isBroadcasting,
    ivideoPk,
    ivideoSk,
    uid: user?.uid,
    ivideo,
  };
};
