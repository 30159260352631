import { Tooltip } from '@chakra-ui/react';

import { FileUploader } from 'react-drag-drop-files';

import { BACKGROUND_COLOR } from '@/components/console-setting/constants/background-color';
import {
  useConsoleSettingValue,
  useConsoleSettingAction,
} from '@/contexts/providers/console-setting-provider';
import { TDS } from '@/libs/chakra';
import { isVideoFileExtension } from '@/utils';
import { gtag } from '@/utils/gtag';

const fileTypes = ['JPEG', 'JPG', 'PNG', 'MP4', 'MKV', 'MOV'];

export function BackgroundSetting() {
  const { backgroundImageFile, backgroundName } = useConsoleSettingValue();
  const { setBackgroundFileAndFileName, setBackgroundLocalPath } =
    useConsoleSettingAction();

  const imagePath = useMemo(() => {
    if (backgroundImageFile) {
      return backgroundImageFile.name;
    }
    if (backgroundName) {
      return backgroundName;
    }
    return '';
  }, [backgroundImageFile, backgroundName]);

  const handleChange = (file: File) => {
    gtag('upload_background_file_click_or_drag');
    const isVideoFile = isVideoFileExtension(file.name);

    if (isVideoFile) {
      const videoUrl = URL.createObjectURL(file);
      setBackgroundLocalPath(videoUrl);
      setBackgroundFileAndFileName(file);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = evt => {
      if (evt.target) {
        setBackgroundLocalPath(evt.target.result);
        setBackgroundFileAndFileName(file);
      }
    };
  };
  return (
    <div className="flex flex-col md:h-60">
      <div
        className="text-white rounded-t-xl py-4 px-6 text-white flex items-center gap-0.5"
        style={BACKGROUND_COLOR}
      >
        <TDS.Typo weight="extrabold">Background</TDS.Typo>
        <Tooltip
          hasArrow
          bg="gray.900"
          label="Select video or image backgrounds."
          placement="right"
          onOpen={() => gtag('open_background_tooltip_hover')}
        >
          <i className="i-t-info-small cursor-pointer" />
        </Tooltip>
      </div>
      <div className="flex-1 bg-white rounded-b-xl p-6">
        <FileUploader handleChange={handleChange} name="file" types={fileTypes}>
          {imagePath && (
            <TDS.Typo className="block text-grey-600" size="sm">
              Uploaded
            </TDS.Typo>
          )}
          {imagePath && <TDS.Typo weight="bold">{imagePath}</TDS.Typo>}
          <div className="border border-solid border-grey-200 rounded-lg w-full flex flex-col items-center justify-center gap-1 px-4 py-3 mt-2">
            <i className="i-t-plus text-3xl" />
            <span className="text-black">Choose a file or drag it here</span>
          </div>
        </FileUploader>
      </div>
    </div>
  );
}
