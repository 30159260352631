import { useShallow } from 'zustand/react/shallow';

import { useTranslation } from 'react-i18next';

import { EmailSignInButton } from '@/components/auth/email-sign-in-button';
import { EmailSignInView } from '@/components/auth/email-sign-in-view';
import { FacebookSignInButton } from '@/components/auth/facebook-sign-in-button';
import { GoogleSignInButton } from '@/components/auth/google-sign-in-button';
import {
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  TDS,
  useDisclosure,
} from '@/components/ui';
import { ErrorManager } from '@/libs/auth/managers/error';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';
import { cn } from '@/utils/cn';

const TYPECAST_REGISTOR_URL = import.meta.env.VITE_TYPECAST_REGISTOR_URL;
/**
 * TODO: google sign in button 랜더링시 크기 설정 관련 문서화 필요
 */
export function SignInModal() {
  const isAuthorized = useUserStore(state => state.isAuthorized);
  const { onClose } = useDisclosure();
  const loadingStore = useLoadingStore(useShallow(state => state));
  const [isEmailSignInOpen, setIsEmailSignInOpen] = useState<boolean>(false);
  const accountErrorHandler = useDisclosure();
  const { t, i18n } = useTranslation('signIn');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const handleSignInStart = () => {
    loadingStore.showLoading();
  };

  const handleSignInEnd = () => {
    loadingStore.hideLoading();
  };

  const handleSignInError = (err: Error) => {
    if (!ErrorManager.isTypecastAuthError(err)) {
      setErrorMessage(t(`error.auth/common`));
      return;
    }
    if (i18n.exists(`error.${err.code}`, { ns: 'signIn' })) {
      setErrorMessage(t(`error.${err.code}`));
      return;
    }
    setErrorMessage(t(`error.auth/common`));
  };

  const showEmailSignIn = () => {
    setIsEmailSignInOpen(true);
  };
  const hideEmailSignIn = () => {
    setIsEmailSignInOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={!isAuthorized}
        onClose={onClose}
        isCentered
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <div
              style={{ maxWidth: '460px', width: '100%', padding: '3rem' }}
              className="relative h-628px bg-white flex flex-col items-stretch justify-center rounded-32px overflow-hidden gap-10"
            >
              {loadingStore.isLoading && (
                <div className="absolute bottom-0 left-0 right-0 top-0 z-10 flex items-center justify-center rounded-2xl bg-white/60">
                  <TDS.Spinner color="grey" size="lg" />
                </div>
              )}
              <TDS.Typo
                as="h2"
                size="2xl"
                weight="bold"
                className="text-center"
              >
                로그인
              </TDS.Typo>
              <div className="flex flex-col place-items-center gap-4">
                <GoogleSignInButton
                  onBeforeSignIn={handleSignInStart}
                  onSignInError={handleSignInError}
                  onAfterSignIn={handleSignInEnd}
                />
                <FacebookSignInButton
                  onBeforeSignIn={handleSignInStart}
                  onSignInError={handleSignInError}
                  onAfterSignIn={handleSignInEnd}
                />
                <EmailSignInButton onClick={showEmailSignIn} />
              </div>
              {errorMessage && (
                <TDS.Typo
                  as="p"
                  color="error.500"
                  textAlign={'center'}
                  size="md"
                  weight="medium"
                  aria-errormessage={errorMessage}
                >
                  {errorMessage}
                </TDS.Typo>
              )}
              <Divider />
              <div>
                <TDS.Button
                  as="a"
                  href={TYPECAST_REGISTOR_URL}
                  rel="noreferrer"
                  variant="outline"
                  colorScheme="grey"
                  size="md"
                  rounded="full"
                  w="full"
                  mb={2}
                >
                  타입캐스트로 회원가입 하러가기
                </TDS.Button>
                <TDS.Typo
                  size="xs"
                  variant="normal"
                  className="block color-grey-600 text-center w-full"
                >
                  <b className="color-grey-900">{t('helper-text.typecast')}</b>
                  에서 회원가입을 완료한 후에{' '}
                  <b className="color-grey-900">
                    {t('helper-text.live-console')}
                  </b>
                  에서 로그인해 주세요
                </TDS.Typo>
              </div>
              {isEmailSignInOpen && (
                <EmailSignInView
                  className={cn(
                    'absolute w-full h-full left-0 top-0 bg-white px-12',
                    {},
                  )}
                  onClose={hideEmailSignIn}
                  onError={handleSignInError}
                  onBeforeSignIn={handleSignInStart}
                  onAfterSignIn={handleSignInEnd}
                  errorMessage={errorMessage}
                />
              )}
            </div>
            <Modal
              isOpen={accountErrorHandler.isOpen}
              onClose={accountErrorHandler.onClose}
              isCentered
            >
              <ModalOverlay />
              <ModalContent rounded={16}>
                <ModalHeader>로그인 실패</ModalHeader>
                <ModalBody>
                  <TDS.Typo as="p" color="grey.700">
                    계정에 문제가 발생해서 로그인이 불가능합니다.
                  </TDS.Typo>
                </ModalBody>
                <ModalFooter>
                  <TDS.Button
                    colorScheme="primary"
                    size="md"
                    onClick={accountErrorHandler.onClose}
                  >
                    닫기
                  </TDS.Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
