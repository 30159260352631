import { isDevMode } from '@/utils/env-info';

export function getCookie(cookieName: string) {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const decodedCookieList = decodedCookie.split(';');
  for (let i = 0; i < decodedCookieList.length; i++) {
    let cookie = decodedCookieList[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return undefined;
}

function getCookieOptionsByDomain() {
  if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    return 'localhost';
  } else if (isDevMode) {
    return 'icepeak.ai; secure;';
  } else {
    return 'typecast.ai; secure;';
  }
}

export function deleteCookie(name: string) {
  document.cookie = `${name}=; path=/; expires='-1; domain=${getCookieOptionsByDomain()}`;
}
