import { useMutation, useQueryClient } from '@tanstack/react-query';
import { overlay } from 'overlay-kit';

import { consoleApi } from '@/api/console-api';
import { ConsoleSettingBaseModal } from '@/components/console-setting/console-setting-base-modal';
import { useIvideoBroadcastsQuery } from '@/components/console-setting/hooks/use-ivideo-broadcasts-query';
import { TDS, useToast } from '@/components/ui';
import { QUERY_KEY } from '@/constants/query-key';
import { gtag } from '@/utils/gtag';

type StopLiveMutateParams = {
  ivideoPk: string;
  ivideoSk: string;
};

export function StopLiveButton() {
  const stopLive = () => {
    gtag('stop_live_button_click');
    overlay.open(({ isOpen, close }) => {
      return <StopLiveConfirmModal isOpen={isOpen} onClose={close} />;
    });
  };

  return (
    <>
      <TDS.Button
        className="border border-solid"
        size="md"
        variant="ghost"
        colorScheme="whiteAlpha"
        leftIcon={<i className="i-nc-stop-circle-orange" />}
        onClick={stopLive}
      >
        <TDS.Typo weight="medium" className="text-white">
          Stop Live
        </TDS.Typo>
      </TDS.Button>
    </>
  );
}

type StopLiveConfirmModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

function StopLiveConfirmModal({ isOpen, onClose }: StopLiveConfirmModalProps) {
  const { ivideoPk, ivideoSk, uid } = useIvideoBroadcastsQuery();
  const { mutateAsync: stopLiveMutateAsync, isPending } = useMutation({
    mutationFn: ({ ivideoPk, ivideoSk }: StopLiveMutateParams) => {
      return consoleApi.deleteIvideo(ivideoPk, ivideoSk);
    },
  });

  const queryClient = useQueryClient();
  const toast = useToast();

  const stopLive = async () => {
    if (!ivideoPk || !ivideoSk) {
      return;
    }
    gtag('stop_live_confirm_button_click');
    try {
      await stopLiveMutateAsync({ ivideoPk, ivideoSk });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.IVIDEO.BROADCASTS, uid],
      });
      onClose();
    } catch (error) {
      toast({ status: 'error', description: 'Failed to stop live' });
    }
  };

  return (
    <ConsoleSettingBaseModal
      isOpen={isOpen}
      onClose={onClose}
      title="Stop live stream"
      okButtonText="Stop"
      cancelButtonText="Cancel"
      isLoadingOk={isPending}
      onClickOk={stopLive}
      onClickCancel={() => {
        gtag('stop_live_cancel_button_click');
        onClose();
      }}
    >
      <TDS.Typo>Your live stream will stop immediately.</TDS.Typo>
    </ConsoleSettingBaseModal>
  );
}
