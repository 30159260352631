import { overlay } from 'overlay-kit';

import { ConsoleSettingBaseModal } from '@/components/console-setting/console-setting-base-modal';
import { useUpdateVtube } from '@/components/console-setting/hooks/use-update-vtube';
import {
  useConsoleSettingAction,
  useConsoleSettingValue,
} from '@/contexts/providers/console-setting-provider';
import { TDS } from '@/libs/chakra';

export function ApplyConsoleSetting() {
  const { updateVtube, isPendingUpdateVtube } = useUpdateVtube();
  const { resetToSnapShot } = useConsoleSettingAction();
  const { isDirty } = useConsoleSettingValue();

  const openCancelConfirmModal = () => {
    overlay.open(({ isOpen, close }) => {
      return (
        <ConsoleSettingBaseModal
          title="Discard changes"
          isOpen={isOpen}
          onClose={close}
          okButtonText="Discard"
          cancelButtonText="Close"
          onClickOk={() => {
            resetToSnapShot();
            close();
          }}
          onClickCancel={close}
        >
          <TDS.Typo>Do you want to revert your modifications?</TDS.Typo>
        </ConsoleSettingBaseModal>
      );
    });
  };

  return (
    <div className="flex flex-col md:flex-row gap-3 w-full mt-4 md:mt-0">
      <TDS.Button
        className="md:w-4/12 "
        variant="outline"
        colorScheme="whiteAlpha"
        onClick={openCancelConfirmModal}
        isDisabled={!isDirty}
      >
        Discard Changes
      </TDS.Button>
      <TDS.Button
        className="md:w-8/12 md:order-2"
        onClick={() => updateVtube({ isShowSuccessToast: true })}
        isLoading={isPendingUpdateVtube}
        isDisabled={!isDirty}
      >
        Apply changes
      </TDS.Button>
    </div>
  );
}
