import { useQuery } from '@tanstack/react-query';

import { PropsWithChildren, ReactElement } from 'react';

import { consoleApi } from '@/api/console-api';
import type { Prompt } from '@/api/types/console-setting';
import { ApplyConsoleSetting } from '@/components/console-setting/apply-console-setting';
import { BackgroundSetting } from '@/components/console-setting/background-setting';
import { CharacterPreview } from '@/components/console-setting/character-preview';
import { ChatSetting } from '@/components/console-setting/chat-setting';
import { ConsoleSettingHeader } from '@/components/console-setting/console-setting-header';
import { ExampleSetting } from '@/components/console-setting/example-setting';
import { useIvideoBroadcastsQuery } from '@/components/console-setting/hooks/use-ivideo-broadcasts-query';
import { IdentitySetting } from '@/components/console-setting/identity-setting';
import { SelectCharacter } from '@/components/console-setting/select-character';
import { TDS, Tooltip } from '@/components/ui';
import { QUERY_KEY } from '@/constants/query-key';
import {
  type Example,
  ConsoleSettingProvider,
  DEFAULT_EXAMPLES,
} from '@/contexts/providers/console-setting-provider';
import { useScreenStore } from '@/stores/screen';
import { useUserStore } from '@/stores/user';
import { Actor } from '@/utils/actor-id';

const BACKGROUND_COLOR = { backgroundColor: '#4C4C4C' };

export default function ConsoleSettingPage(): ReactElement {
  const { isMobile } = useScreenStore();
  const isAuthorized = useUserStore(state => state.isAuthorized);
  const { isBroadcasting } = useIvideoBroadcastsQuery();
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEY.VTUBE.INDEX],
    queryFn: () => consoleApi.getVtubeSetting(),
    select: data => {
      const systemPrompt = data.persona?.prompt_list.find(
        prompt => prompt.prompt_type === 'system',
      );
      const mapPromptToExample = (prompt: Prompt) => {
        return {
          type: prompt.prompt_type === 'user' ? 'question' : 'answer',
          message: prompt.message,
        } as Example;
      };

      const STREAMER_LIST = [
        Actor.Camila,
        Actor.ChanGu,
        Actor.HaJun,
      ] as string[];
      // 이전에 테스트하였던 계정은 'camila' | 'hajun' 등 이와 같이 값이 들어가버려 오류가 발생.
      // 이를 해결하고자 유효한 string값인 경우만 값으로 설정하도록 수정
      const selectedCharacter =
        data.persona && STREAMER_LIST.includes(data.persona.streamer_name)
          ? data.persona.streamer_name
          : Actor.Camila;

      return {
        selectedCharacter: selectedCharacter as Actor,
        backgroundName: data.vtube?.background_name ?? '',
        backgroundPath: data.vtube?.background_path ?? '',
        backgroundUrl: data.vtube?.background_url ?? '',
        identity: systemPrompt ? systemPrompt.message : '',
        bubbleFlag: data.vtube?.bubble_flag ?? false,
        examples:
          data.persona?.prompt_list
            .filter(({ prompt_type }: Prompt) => prompt_type !== 'system')
            .map(mapPromptToExample) ?? DEFAULT_EXAMPLES,
      };
    },
    enabled: isAuthorized,
  });

  if (isLoading || !data) {
    return <MainLayout />;
  }

  return (
    <MainLayout>
      <ConsoleSettingProvider initialValues={data}>
        <>
          <ConsoleSettingHeader />
          <section className="flex gap-4 w-full md:p-8 md:pb-0 px-4">
            {isBroadcasting && <ApplyConsoleSetting />}
          </section>
          <div className="flex flex-col md:flex-row w-full p-8 gap-6 md:overflow-y-scroll bg-black">
            <section className="md:w-7/12">
              <CharacterPreview />
              {!isMobile && (
                <div className="mt-6 flex gap-3">
                  <section className="flex-1">
                    <ChatSetting />
                  </section>
                  <section className="flex-1">
                    <BackgroundSetting />
                  </section>
                </div>
              )}
            </section>
            <section className="md:w-5/12 flex flex-col">
              <div
                className="text-white rounded-t-xl py-4 px-6 flex items-center gap-0.5"
                style={BACKGROUND_COLOR}
              >
                <TDS.Typo weight="extrabold">Streamer</TDS.Typo>
                <Tooltip
                  hasArrow
                  bg="gray.900"
                  label="Customize streamer character and personality."
                  placement="right"
                >
                  <i className="i-t-info-small cursor-pointer" />
                </Tooltip>
              </div>
              <div className="bg-white font-medium rounded-b-xl">
                <div className="p-6">
                  <SelectCharacter />
                </div>
                <div className="flex flex-col md:flex-row gap-4 p-6 border-t border-solid">
                  <section className="flex-1">
                    <IdentitySetting />
                  </section>
                  <section className="flex-1">
                    <ExampleSetting />
                  </section>
                </div>
              </div>
            </section>
            {isMobile && (
              <>
                <section>
                  <ChatSetting />
                </section>
                <section>
                  <BackgroundSetting />
                </section>
              </>
            )}
          </div>
        </>
      </ConsoleSettingProvider>
    </MainLayout>
  );
}

function MainLayout({ children }: PropsWithChildren): ReactElement {
  return (
    <main className="flex flex-col w-full h-screen bg-black">{children}</main>
  );
}
