import {
  Text,
  useToast,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';

import TypecastLogo from '@/assets/typecast-logo.svg';
import { useIvideoBroadcastsQuery } from '@/components/console-setting/hooks/use-ivideo-broadcasts-query';
import { LiveNowTimer } from '@/components/console-setting/live-now-timer';
import { StartLiveButton } from '@/components/console-setting/start-live-button';
import { StopLiveButton } from '@/components/console-setting/stop-live-button';
import { BUSINESS_CONTACT_URL } from '@/constants/external-link';
import { TDS } from '@/libs/chakra';
import { useScreenStore } from '@/stores/screen';
import { useUserStore } from '@/stores/user';
import { cn } from '@/utils';
import { isDevMode, isLocalOnlyDevMode } from '@/utils/env-info';
import { gtag } from '@/utils/gtag';

export function ConsoleSettingHeader() {
  const { user } = useUserStore();
  const { isMobile } = useScreenStore();
  const { isBroadcasting, ivideo } = useIvideoBroadcastsQuery();
  const toast = useToast();

  const streamKey = useMemo(() => {
    if (isLocalOnlyDevMode) {
      return `http://localhost:3001/u/${user?.uid ?? 'unknown user uid'}`;
    }
    if (isDevMode) {
      return `${import.meta.env.VITE_LIVE_URL}/u/${user?.uid ?? 'unknown user uid'}`;
    }
    return `${import.meta.env.VITE_LIVE_URL}/u/${user?.uid ?? ''}`;
  }, [user?.uid]);

  return (
    <header
      className="w-full md:h-22 flex flex-col md:flex-row md:justify-between gap-2 px-4 py-4 md:px-8 "
      style={{ backgroundColor: '#333333' }}
    >
      <div className="flex justify-between my-auto">
        <div className="flex flex-col items-end">
          <img src={TypecastLogo} alt="Typecast Logo" className="w-40" />
          <div>
            <Text
              as="span"
              className="tracking-wide text-white"
              fontSize="sm"
              fontWeight="medium"
            >
              Live Streaming
            </Text>
            <Text
              as="span"
              className="border-solid text-white rounded-lg border-2 ml-0.5 px-1 py-0.5"
              fontSize="sm"
            >
              Beta
            </Text>
          </div>
        </div>
        {isMobile && <ContactButton />}
      </div>
      <div className="flex flex-col md:flex-row gap-2 md:gap-4 md:items-center mt-4 md:mt-0">
        <Text className="text-white md:truncate text-xl">Stream Key</Text>
        <div className="md:flex md:w-160 justify-between bg-black rounded-lg p-4 items-center">
          <Text className="text-white md:truncate">{streamKey}</Text>
          <div className="flex gap-2 md:mt-0 mt-2">
            <TDS.Button
              className="flex-1"
              size="sm"
              leftIcon={<i className="i-t-duplicate" />}
              variant="outline"
              colorScheme="whiteAlpha"
              onClick={() => {
                gtag('copy_stream_key_button_click');
                navigator.clipboard.writeText(streamKey);
                toast({
                  title: 'Copied',
                  status: 'success',
                  position: 'top',
                  duration: 1000,
                });
              }}
            >
              Copy
            </TDS.Button>
            <TDS.Button
              className="flex-1"
              size="sm"
              leftIcon={<i className="i-t-link_to_page-small" />}
              variant="outline"
              colorScheme="whiteAlpha"
              onClick={() => {
                gtag('open_stream_key_button_click');
                window.open(streamKey, '_blank');
              }}
            >
              Open
            </TDS.Button>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <div className="flex gap-3 items-center justify-between bg-black py-2 px-4 rounded-2xl w-full">
          <div className="flex items-center gap-2">
            <span
              className={cn('rounded-full border-3 border-solid w-4 h-4', {
                'bg-red-500 border-red-500 w-3 h-3': isBroadcasting,
              })}
            />
            <Text
              fontWeight="bold"
              className="text-white flex text-sm md:text-md"
            >
              <span>{isBroadcasting ? 'Live now' : 'Live stream offline'}</span>
              {isBroadcasting && ivideo && ivideo.start_ts && (
                <LiveNowTimer startTime={ivideo.start_ts} />
              )}
            </Text>
          </div>

          {isBroadcasting ? <StopLiveButton /> : <StartLiveButton />}

          {!isMobile && <ContactButton />}
        </div>
      </div>
    </header>
  );
}

function ContactButton() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <TDS.Button
          size="sm"
          className="text-white min-h-10 min-w-10 flex justify-center items-center"
          style={{
            backgroundColor: '#4A4A4A',
            borderRadius: '50%',
          }}
        >
          <Text className="text-grey-200 text-2xl md:text-lg">?</Text>
        </TDS.Button>
      </PopoverTrigger>
      <PopoverContent width={112}>
        <TDS.Button
          variant="ghost"
          leftIcon={<i className="i-t-support-chat bg-grey-900 text-5" />}
          onClick={() => {
            gtag('contact_button_click');
            window.open(BUSINESS_CONTACT_URL);
          }}
        >
          <TDS.Typo size="sm" className="text-grey-900">
            고객문의
          </TDS.Typo>
        </TDS.Button>
      </PopoverContent>
    </Popover>
  );
}
