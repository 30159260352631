export const ENV_MODE = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
} as const;

export type EnvMode = (typeof ENV_MODE)[keyof typeof ENV_MODE];

export const isLocalOnlyDevMode = import.meta.env.DEV;
export const isDevMode = import.meta.env.MODE === ENV_MODE.DEVELOPMENT;
export const isStagingMode = import.meta.env.MODE === ENV_MODE.STAGING;
export const isProdMode = import.meta.env.MODE === ENV_MODE.PRODUCTION;
export const isAlphaMode = import.meta.env.VITE_CIRCLE_TAG?.includes('alpha');

export const getEnvMode = (): EnvMode => {
  const mode = import.meta.env.MODE as EnvMode;
  switch (mode) {
    case ENV_MODE.DEVELOPMENT: {
      return ENV_MODE.DEVELOPMENT;
    }
    case ENV_MODE.STAGING: {
      return ENV_MODE.STAGING;
    }
    case ENV_MODE.PRODUCTION: {
      return ENV_MODE.PRODUCTION;
    }
    default: {
      assertUnreachable(mode);
    }
  }
};

/**
 * @see https://stackoverflow.com/questions/39419170/how-do-i-check-that-a-switch-block-is-exhaustive-in-typescript
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertUnreachable(x: never): never {
  console.error('x: ', x);
  throw new Error("Didn't expect to get here");
}
