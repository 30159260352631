import { PropsWithChildren } from 'react';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  TDS,
} from '@/components/ui';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  okButtonText: string;
  cancelButtonText: string;
  isLoadingOk?: boolean;
  onClickOk: () => void;
  onClickCancel: () => void;
};

export function ConsoleSettingBaseModal({
  isOpen,
  onClose,
  title,
  okButtonText,
  cancelButtonText,
  onClickOk,
  isLoadingOk = false,
  onClickCancel,
  children,
}: PropsWithChildren<Props>) {
  const handleClickCancel = () => {
    onClickCancel();
  };
  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="py-12 px-15 ">
        <ModalBody className="text-center text-grey-900">
          <TDS.Typo className="block mb-10" size="3xl" weight="bold">
            {title}
          </TDS.Typo>
          {children}
          <div className="flex flex-col gap-3 mt-10">
            <TDS.Button onClick={onClickOk} isLoading={isLoadingOk}>
              {okButtonText}
            </TDS.Button>
            <TDS.Button
              variant="outline"
              colorScheme="grey"
              onClick={handleClickCancel}
            >
              {cancelButtonText}
            </TDS.Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
