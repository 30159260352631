import { useMutation, useQueryClient } from '@tanstack/react-query';
import { overlay } from 'overlay-kit';

import { consoleApi } from '@/api/console-api';
import { ConsoleSettingBaseModal } from '@/components/console-setting/console-setting-base-modal';
import { useIvideoBroadcastsQuery } from '@/components/console-setting/hooks/use-ivideo-broadcasts-query';
import { useUpdateVtube } from '@/components/console-setting/hooks/use-update-vtube';
import { TDS, useToast } from '@/components/ui';
import { QUERY_KEY } from '@/constants/query-key';
import { useUserStore } from '@/stores/user';
import { isDevMode, isLocalOnlyDevMode } from '@/utils/env-info';
import { gtag } from '@/utils/gtag';

export function StartLiveButton() {
  const { startLiveMutateAsync, isPending, uid } = useStartLive();
  const { isBroadcasting } = useIvideoBroadcastsQuery();
  const { updateVtube, isPendingUpdateVtube } = useUpdateVtube();
  const queryClient = useQueryClient();

  const startLive = async () => {
    if (!isBroadcasting) {
      updateVtube({ isShowSuccessToast: false });
    }
    gtag('start_live_button_click');
    try {
      await startLiveMutateAsync();
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.IVIDEO.BROADCASTS, uid],
      });
      overlay.open(({ isOpen, close }) => {
        return <StartLiveSuccessModal isOpen={isOpen} onClose={close} />;
      });
    } catch (error) {
      overlay.open(({ isOpen, close }) => {
        return <StartLiveFailureModal isOpen={isOpen} onClose={close} />;
      });
    }
  };

  return (
    <>
      <TDS.Button
        size="md"
        leftIcon={<i className="i-t-broadcast" />}
        onClick={startLive}
        isDisabled={isPending || isPendingUpdateVtube}
      >
        Start Live
      </TDS.Button>
    </>
  );
}

type StartLiveSuccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

function StartLiveSuccessModal({
  isOpen,
  onClose,
}: StartLiveSuccessModalProps) {
  const { user } = useUserStore();
  const openLive = () => {
    gtag('go_to_stream_button_click');
    if (isLocalOnlyDevMode) {
      window.open(
        `http://localhost:3001/u/${user?.uid ?? 'unknonw user uid'}`,
        '_blank',
      );
      return;
    }
    if (isDevMode) {
      window.open(
        `${import.meta.env.VITE_LIVE_URL}/u/${user?.uid ?? 'unknown user uid'}`,
        '_blank',
      );
      return;
    }
    window.open(
      `${import.meta.env.VITE_LIVE_URL}/u/${user?.uid ?? ''}`,
      '_blank',
    );
  };
  return (
    <ConsoleSettingBaseModal
      isOpen={isOpen}
      onClose={onClose}
      title="Live stream started"
      okButtonText="Go to Stream"
      cancelButtonText="Close"
      onClickOk={openLive}
      onClickCancel={() => {
        gtag('close_live_stream_started_modal_button_click');
        onClose();
      }}
    >
      <TDS.Typo className="block pb-3" size="lg">
        Your live stream has successfully begun.
      </TDS.Typo>
      <TDS.Typo className="text-grey-600">
        During the beta test period, streaming is limited to 5 minutes. For
        extended streaming, please contact us.
      </TDS.Typo>
    </ConsoleSettingBaseModal>
  );
}

type StartLiveFailureModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

function StartLiveFailureModal({
  isOpen,
  onClose,
}: StartLiveFailureModalProps) {
  const { startLiveMutateAsync, isPending } = useStartLive();
  const toast = useToast();

  const retryStartLive = async () => {
    try {
      await startLiveMutateAsync();
    } catch (error) {
      toast({ status: 'error', description: 'Retry Failed' });
    }
  };

  return (
    <ConsoleSettingBaseModal
      isOpen={isOpen}
      onClose={onClose}
      title="Live stream failed"
      okButtonText="Retry"
      cancelButtonText="Close"
      onClickOk={retryStartLive}
      onClickCancel={onClose}
      isLoadingOk={isPending}
    >
      <TDS.Typo className="block pb-3" size="lg">
        No available servers for streaming at the moment. Please try again in 5
        minutes. If the issue persists, contact us.
      </TDS.Typo>
    </ConsoleSettingBaseModal>
  );
}

const useStartLive = () => {
  const { user } = useUserStore(state => ({
    user: state.user,
  }));
  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: (brokerPk: string) => consoleApi.postIvideo(brokerPk),
  });

  const startLiveMutateAsync = async () => {
    if (!user?.uid) {
      alert('user not found');
      return;
    }
    await mutateAsync(user?.uid);
  };
  return { startLiveMutateAsync, isPending, uid: user?.uid, isSuccess };
};
