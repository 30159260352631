import { createTimeModel, useTimeModel } from 'react-compound-timer';

import { dayjs } from '@/utils/time';

const MAX_SECONDS = 60 * 5; //5min

export function LiveNowTimer({ startTime }: { startTime: number }) {
  const timer = useMemo(() => {
    const startTs = dayjs.unix(startTime);
    const now = dayjs();
    const diffSecond = now.diff(startTs, 'second');
    const leftSecond = MAX_SECONDS - diffSecond;
    return createTimeModel({
      initialTime: leftSecond * 1000,
      direction: 'backward',
      startImmediately: true,
    });
  }, [startTime]);
  const { value } = useTimeModel(timer);
  return (
    <div className="ml-1">
      {`( `}
      <span className="text-black bg-white">
        {value.m}:{value.s.toString().padStart(2, '0')}
      </span>
      <span className="ml-1.5">left in the live stream</span>
      {`)`}
    </div>
  );
}
