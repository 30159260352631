import { useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';

import { consoleApi } from '@/api/console-api';
import { s3Api } from '@/api/s3-api';
import type {
  Prompt,
  VtubeSettingRequestDTO,
} from '@/api/types/console-setting';
import {
  type Example,
  useConsoleSettingAction,
  useConsoleSettingValue,
} from '@/contexts/providers/console-setting-provider';
import { getActorId } from '@/utils/actor-id';

export const useUpdateVtube = () => {
  const {
    selectedCharacter,
    bubbleFlag,
    identity,
    examples,
    backgroundImageFile,
    backgroundPath,
    backgroundName,
  } = useConsoleSettingValue();
  const {
    setBackgroundPath,
    setBackgroundImageFile,
    updateSnapShotByCurrentSetting,
  } = useConsoleSettingAction();

  const toast = useToast();
  const {
    mutateAsync: updateVtubeSettingMutateAsync,
    isPending: isPendingUpdateVtube,
  } = usePatchVtubeMutation();

  const { mutateAsync: postVtubeBackgroundUrlMutateAsync } =
    usePostVtubeBackgroundUrlMutation();

  const { mutateAsync: uploadImageToS3MutateAsync } =
    useUploadImageToS3Mutation();

  const promptListByExamples = mapExamplesToPromptList(examples);

  const updateVtube = async ({
    isShowSuccessToast = true,
  }: {
    isShowSuccessToast: boolean;
  }) => {
    let bgPath = backgroundPath;

    if (isPendingUpdateVtube) {
      return;
    }

    if (backgroundImageFile) {
      try {
        const response = await postVtubeBackgroundUrlMutateAsync(
          backgroundImageFile.name,
        );
        await uploadImageToS3MutateAsync({
          s3Url: response.background_url,
          file: backgroundImageFile,
        });
        bgPath = response.background_path;
        setBackgroundPath(response.background_path);
      } catch (error) {
        toast({
          position: 'top',
          status: 'error',
          description: 'Failed to upload background image.',
        });
      }
    }

    try {
      await updateVtubeSettingMutateAsync({
        vtube: {
          bubble_flag: bubbleFlag,
          background_path: bgPath,
          background_name: backgroundImageFile?.name
            ? backgroundImageFile.name
            : backgroundName
              ? backgroundName
              : '',
        },
        persona: {
          streamer_id: getActorId(selectedCharacter),
          streamer_name: selectedCharacter,
          prompt_list: [
            { prompt_type: 'system', message: identity },
            ...promptListByExamples,
          ],
        },
      });
      setBackgroundImageFile(null); // 업로드 후 초기화
      updateSnapShotByCurrentSetting();
      if (isShowSuccessToast) {
        toast({
          position: 'top',
          colorScheme: 'grey-100',
          title: 'Apply changes',
          description:
            'Your changes will be reflected in the live stream within 10 seconds.',
          containerStyle: { backgroundColor: '#262626' },
        });
      }
    } catch (error) {
      /**
       * TODO: 에러 응답 구조에 맞춰서 에러 처리
       */
      toast({
        position: 'top',
        status: 'error',
        description: 'Apply changes Failed',
      });
    }
  };
  return { updateVtube, isPendingUpdateVtube };
};

const usePatchVtubeMutation = () => {
  return useMutation({
    mutationFn: (data: VtubeSettingRequestDTO) =>
      consoleApi.updateVtubeSetting(data),
  });
};

const usePostVtubeBackgroundUrlMutation = () => {
  return useMutation({
    mutationFn: (backgroundName: string) =>
      consoleApi.postVtubeBackgroundUrl(backgroundName),
  });
};

const useUploadImageToS3Mutation = () => {
  return useMutation({
    mutationFn: ({ s3Url, file }: { s3Url: string; file: File }) =>
      s3Api.uploadImageToS3(s3Url, file),
  });
};

const mapExamplesToPromptList = (example: Example[]) => {
  const filterEmptyMessageExamples = example.filter(
    example => example.message !== '',
  );

  const promptList = filterEmptyMessageExamples.map(example => {
    return {
      prompt_type: example.type === 'question' ? 'user' : 'assistant',
      message: example.message,
    } as Prompt;
  });

  return promptList;
};
